import { ROUTE } from './config'

import { Home } from '~/views/app/Home'
import { MonsterDetail } from '~/views/app/Home/MonsterDetail'
import { Farm } from '~/views/app/Farm'
import { FarmDetail } from '~/views/app/Farm/FarnDetail'
import { Items } from '~/views/app/Items'
import ItemsDetail from '~/views/app/Items/ItemsDetail'
import HashNFT from '~/views/app/HashNFT'
import HashNFTDetail from '~/views/app/HashNFT/HashNFTDetail'

//Shop
import BitShop from '~/views/app/Shop/Bit'
import FarmShop from '~/views/app/Shop/Farm'
import GeneralBox from '~/views/app/Shop/GeneralBox'
import GenesisBox from '~/views/app/Shop/GenesisBox'
import TrainingItem from '~/views/app/Shop/TrainingItem'

//Profile
// import MyCreatedSales from '~/views/app/Profile/1MyCreatedSales'
import AssetsMonsters from '~/views/app/Profile/2MyAssets/1Monsters'
import AssetsMemoryMonsters from '~/views/app/Profile/2MyAssets/7MemoryMonsters'
import AssetsFarms from '~/views/app/Profile/2MyAssets/2Farms'
import AssetsItems from '~/views/app/Profile/2MyAssets/3Items'
import AssetsRewards from '~/views/app/Profile/2MyAssets/6Rewards'
import ProfileBridge from '~/views/app/Profile/3Bridge'
import ProfileTradeHistory from '~/views/app/Profile/4TradeHistory'
import ProfileAccountSettings from '~/views/app/Profile/5AccountSettings'
import { AssetHash } from '~/views/app/Profile/2MyAssets/5Hash'
import MonsterSale from '../app/Profile/1MyCreatedSales/components/1MonsterSale'
import FarmSale from '../app/Profile/1MyCreatedSales/components/2FarmSale'
import ItemSale from '../app/Profile/1MyCreatedSales/components/3ItemSale'
import HashSale from '../app/Profile/1MyCreatedSales/components/4HashSale'
import MonsterMemoryDetail from '../app/Profile/2MyAssets/7MemoryMonsters/MonsterMemoryDetail'

const routes = [
  //Home
  {
    name: ROUTE.HOME.TITLE,
    path: ROUTE.HOME.PATH,
    component: Home,
    public: true,
  },
  {
    name: ROUTE.MONSTER_DETAIL.TITLE,
    path: ROUTE.MONSTER_DETAIL.PATH,
    component: MonsterDetail,
    public: true,
  },
  {
    name: ROUTE.MONSTER_MEMORY_DETAIL.TITLE,
    path: ROUTE.MONSTER_MEMORY_DETAIL.PATH,
    component: MonsterMemoryDetail,
    public: true,
  },
  {
    name: ROUTE.FARM.TITLE,
    path: ROUTE.FARM.PATH,
    component: Farm,
    public: true,
  },
  {
    name: ROUTE.FARM_DETAIL.TITLE,
    path: ROUTE.FARM_DETAIL.PATH,
    component: FarmDetail,
    public: true,
  },
  {
    name: ROUTE.ITEM.TITLE,
    path: ROUTE.ITEM.PATH,
    component: Items,
    public: true,
  },
  {
    name: ROUTE.ITEM_DETAIL.TITLE,
    path: ROUTE.ITEM_DETAIL.PATH,
    component: ItemsDetail,
    public: true,
  },
  {
    name: ROUTE.HASH_NFT.TITLE,
    path: ROUTE.HASH_NFT.PATH,
    component: HashNFT,
    public: true,
  },
  {
    name: ROUTE.HASH_NFT_DETAIL.TITLE,
    path: ROUTE.HASH_NFT_DETAIL.PATH,
    component: HashNFTDetail,
    public: true,
  },
  //Shop
  {
    name: ROUTE.GENESIS_BOX.TITLE,
    path: ROUTE.GENESIS_BOX.PATH,
    component: GenesisBox,
    public: true,
  },
  {
    name: ROUTE.GENERAL_BOX.TITLE,
    path: ROUTE.GENERAL_BOX.PATH,
    component: GeneralBox,
    public: true,
  },
  {
    name: ROUTE.TRAINING_ITEM.TITLE,
    path: ROUTE.TRAINING_ITEM.PATH,
    component: TrainingItem,
    public: true,
  },
  {
    name: ROUTE.FARM_SHOP.TITLE,
    path: ROUTE.FARM_SHOP.PATH,
    component: FarmShop,
    public: true,
  },
  {
    name: ROUTE.BIT.TITLE,
    path: ROUTE.BIT.PATH,
    component: BitShop,
    public: true,
  },
  //Profile
  {
    name: ROUTE.PROFILE_MONSTER_SALES.TITLE,
    path: ROUTE.PROFILE_MONSTER_SALES.PATH,
    component: MonsterSale,
    public: false,
  },
  {
    name: ROUTE.PROFILE_FARMS_SALES.TITLE,
    path: ROUTE.PROFILE_FARMS_SALES.PATH,
    component: FarmSale,
    public: false,
  },
  {
    name: ROUTE.PROFILE_ITEMS_SALES.TITLE,
    path: ROUTE.PROFILE_ITEMS_SALES.PATH,
    component: ItemSale,
    public: false,
  },
  {
    name: ROUTE.PROFILE_HASH_SALES.TITLE,
    path: ROUTE.PROFILE_HASH_SALES.PATH,
    component: HashSale,
    public: false,
  },
  {
    name: ROUTE.PROFILE_MONSTERS.TITLE,
    path: ROUTE.PROFILE_MONSTERS.PATH,
    component: AssetsMonsters,
    public: false,
  },
  {
    name: ROUTE.PROFILE_MEMORY_MONSTERS.TITLE,
    path: ROUTE.PROFILE_MEMORY_MONSTERS.PATH,
    component: AssetsMemoryMonsters,
    public: false,
  },
  {
    name: ROUTE.PROFILE_FARMS.TITLE,
    path: ROUTE.PROFILE_FARMS.PATH,
    component: AssetsFarms,
    public: false,
  },
  {
    name: ROUTE.PROFILE_ITEMS.TITLE,
    path: ROUTE.PROFILE_ITEMS.PATH,
    component: AssetsItems,
    public: false,
  },
  {
    name: ROUTE.PROFILE_HASH.TITLE,
    path: ROUTE.PROFILE_HASH.PATH,
    component: AssetHash,
    public: false,
  },
  {
    name: ROUTE.PROFILE_REWARDS.TITLE,
    path: ROUTE.PROFILE_REWARDS.PATH,
    component: AssetsRewards,
    public: false,
  },
  {
    name: ROUTE.PROFILE_BRIDGE.TITLE,
    path: ROUTE.PROFILE_BRIDGE.PATH,
    component: ProfileBridge,
    public: false,
  },
  {
    name: ROUTE.PROFILE_TRADE_HISTORY.TITLE,
    path: ROUTE.PROFILE_TRADE_HISTORY.PATH,
    component: ProfileTradeHistory,
    public: false,
  },
  {
    name: ROUTE.PROFILE_ACCOUNT_SETTINGS.TITLE,
    path: ROUTE.PROFILE_ACCOUNT_SETTINGS.PATH,
    component: ProfileAccountSettings,
    public: false,
  },
]

export default routes
