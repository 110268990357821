import { useEffect, useState } from 'react'
import { Button, Card, Col, Popover, Row } from 'antd'
import { useTranslation } from '~/common/hooks/useTranslation'
import { useLocation, useHistory } from 'react-router-dom'

import BodyCommon from '~/components/shared-components/BodyCommon'

import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import TimeHelper from '~/helpers/time-helper'
import { ParameterFarm } from './components/ParameterFarm'
import { useFarm } from '~/views/redux/hooks/useFarm'
import { CopyAddressCommon } from '~/components/shared-components/CopyAddressCommon'
import LoadingFarmDetail from './LoadingFarmDetail'
import { useCommon } from '~/views/redux/hooks/useCommon'
import configs from '~/configs'
import {
  checkTransactionConfirmation,
  fromWei,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import {
  convertAddressToProperFormat,
  convertOasToUSD,
  formatAddress,
  formatNumber,
  formatTransaction,
} from '~/helpers/common'
import { useSDK } from '@metamask/sdk-react'
import { useAccount } from '~/common/hooks/useAccount'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import {
  ContractFarm,
  ifaceContractFarm,
  ifaceContractMarketplace,
} from '~/blockchain/contract'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import SellModal from '~/components/Modals/SellModal'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import TransactionCompletedSell from '~/components/Modals/TransactionCompletedSell'
import SelectWallet from '~/components/Modals/SelectWallet'
import CheckoutModal from '~/components/Modals/Checkout'
import CancelSell from '~/components/Modals/CancelSell'
import { getImgByFarmTerrain } from '~/helpers/getValueByFarmTerrain'

export const FarmDetail = () => {
  const { sdk } = useSDK()
  // eslint-disable-next-line
  const [txHash, setTxHash] = useState()
  const { user } = useAccount()
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [isModalSuccessSellOpen, setIsModalSuccessSellOpen] = useState(false)
  const [isModalSellOpen, setIsModalSellOpen] = useState(false)

  // eslint-disable-next-line
  const { t } = useTranslation()
  const { page, pageSize, setPage, setPageSize } = useQueryState()
  const location = useLocation()
  const history = useHistory()
  const [dataHistories, setDataHistories] = useState([])
  const [totalData, setTotalData] = useState(0)
  const {
    actions,
    data: { farmDetail, isLoading },
  } = useFarm()

  const {
    actions: actionsCommon,
    data: { allTEMAs },
  } = useCommon()
  const [checkFarmFree, setCheckFarmFree] = useState(false)
  useEffect(() => {
    if (
      farmDetail?.is_free_farm === true
      // ||
      // farmDetail?.is_free_farm === undefined
    ) {
      setCheckFarmFree(true)
    } else if (farmDetail?.is_free_farm === false) {
      setCheckFarmFree(false)
    }
  }, [farmDetail])
  console.log("farmDetail", farmDetail)

  const columns = [
    {
      title: t('farmDetail.trading.event'),
      dataIndex: 'event',
      align: 'center',
      key: 'event',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('farmDetail.trading.from'),
      align: 'center',
      dataIndex: 'from',
      key: 'from',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('farmDetail.trading.to'),
      align: 'center',
      dataIndex: 'to',
      key: 'to',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('farmDetail.trading.price'),
      align: 'center',
      dataIndex: 'price',
      key: 'price',
      render: (data) => {
        // return <div>{formatNumber(data)} OAS</div>
        return (
          <div>
            <Row gutter={4}>
              <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                {formatNumber(data)}
              </Col>
              <Col
                span={12}
                style={{ display: 'flex', justifyContent: 'start' }}
              >
                OAS
              </Col>
            </Row>
          </div>
        )
      },
    },
    {
      title: t('farmDetail.trading.amount'),
      align: 'center',
      dataIndex: 'amount',
      key: 'amount',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('farmDetail.trading.date'),
      align: 'center',
      dataIndex: 'time',
      key: 'time',
      render: (data) => {
        return (
          <div>
            {TimeHelper(Number(data) * 1000, {
              format: 'YYYY-MM-DD HH:mm:ss',
            })}
          </div>
        )
      },
    },
    {
      title: t('farmDetail.trading.txHash'),
      dataIndex: 'txHash',
      align: 'center',
      key: 'txHash',
      render: (data) => {
        const link = `${configs.NETWORK.scan}tx/${data}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatTransaction(data)}
          </a>
        )
      },
    },
  ]

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  useEffect(() => {
    if (user && user !== null) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  const handleShare = () => {
    setIsModalSuccessSellOpen(false)
  }

  const handleCancelSuccessSell = () => {
    setIsModalSuccessSellOpen(false)
  }

  const showModalSell = () => {
    setIsModalSellOpen(true)
  }

  const showModalSuccessSell = () => {
    setIsModalSuccessSellOpen(true)
  }

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const handleCancelModalSell = () => {
    setIsModalSellOpen(false)
  }

  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleSell = async (tokenID, price) => {
    try {
      setLoadingModal(true)
      // eslint-disable-next-line
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let allowance = await ContractFarm.getApproved(tokenID)
        if (
          allowance.toLowerCase() === configs.ADDRESS_MARKETPLACE.toLowerCase()
        ) {
          sell(tokenID, price)
        } else {
          let data = {
            from: providerOAS.accounts[0],
            data: ifaceContractFarm.encodeFunctionData('approve', [
              configs.ADDRESS_MARKETPLACE,
              tokenID,
            ]),
            to: configs.ADDRESS_FARM,
            nonce: nonce,
            gasLimit: 21000,
          }

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          })

          const transaction = await ethersWeb3Provider.sendTransaction(
            String(signedTransaction),
          )
          checkTransactionConfirmation(transaction?.hash).then((r) => {
            if (r === 'confirmed') {
              // eslint-disable-next-line
              console.log('Approve:', r, transaction?.hash)
              sell(tokenID, price)
            }
          })
        }
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        let allowance = await ContractFarm.getApproved(tokenID)
        if (
          allowance.toLowerCase() === configs.ADDRESS_MARKETPLACE.toLowerCase()
        ) {
          sell(tokenID, price)
        } else {
          let nonce = await sdk.getProvider().request({
            method: 'eth_getTransactionCount',
            params: [user.address_wallet, 'latest'],
          });
          sdk
            .getProvider()
            .request({
              method: 'eth_sendTransaction',
              params: [
                {
                  from: user.address_wallet,
                  data: ifaceContractFarm.encodeFunctionData('approve', [
                    configs.ADDRESS_MARKETPLACE,
                    tokenID,
                  ]),
                  to: configs.ADDRESS_FARM,
                  nonce
                },
              ],
            })
            .then((txHash) => {
              checkTransactionConfirmation(txHash).then((r) => {
                if (r === 'confirmed') {
                  // eslint-disable-next-line
                  console.log('Approve:', r, txHash)
                  sell(tokenID, price)
                }
              })
            })
            .catch((error) => {
              setLoadingModal(false)
              // eslint-disable-next-line
              console.error(error)
            })
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }

  const sell = async (tokenID, price) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'createMarketItemSale',
            [configs.ADDRESS_FARM, tokenID, toWei(price), 1],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancelModalSell()
            setTxHash(transaction?.hash)
            showModalSuccessSell()
          }
        })
      } else {
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        });
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'createMarketItemSale',
                  [configs.ADDRESS_FARM, tokenID, toWei(price), 1],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancelModalSell()
                setTxHash(txHash)
                showModalSuccessSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }

  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            farmDetail?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(farmDetail?.price)),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        });
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  farmDetail?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(farmDetail?.price)),
                nonce
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [farmDetail?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }
        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        });
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [farmDetail?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  useEffect(() => {
    if (isNaN(location?.pathname?.slice(13))) {
      history.push('/pageNotFound')
    } else {
      actions?.getDetailFarm(
        location?.pathname?.slice(13),
        () => {},
        () => {
          history.push('/pageNotFound')
        },
      )
    }
    actionsCommon.getTema()
  }, [txHash])

  useEffect(() => {
    if (farmDetail?.farm_nft_id) {
      actionsCommon.getHistoriesToken(
        {
          addressContract: configs?.ADDRESS_FARM,
          tokenId: farmDetail.farm_nft_id,
          page: page,
        },
        async (res) => {
          let data = await Promise.all(
            res.data.map((temp, index) => {
              return {
                key: index,
                event:
                  temp?.type === 'OrderCreated'
                    ? 'For Sale'
                    : temp?.type === 'OrderSuccessful'
                    ? 'Buy'
                    : undefined,
                time: temp?.timestamp,
                from: temp?.from,
                to: temp?.to,
                price: fromWei(temp?.priceInWei),
                amount: temp.amount,
                txHash: temp?.transactionHash,
                created_at: temp?.timestamp,
              }
            }),
          )
          setTotalData(res?.total)
          setDataHistories(data)
        },
      )
    }
  }, [txHash, page, farmDetail])

  const ButtonFarm = () => {
    if (farmDetail?.orderId) {
      if (
        farmDetail?.seller?.toLowerCase() ===
          user?.address_wallet?.toLowerCase() &&
        farmDetail?.owner?.toLowerCase() ===
          configs.ADDRESS_MARKETPLACE.toLowerCase()
      ) {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCancelSell()
            }}
          >
            Cancel
          </Button>
        )
      } else {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCheckout()
            }}
          >
            Buy Now
          </Button>
        )
      }
    } else {
      if (user?.address_wallet === farmDetail?.owner) {
        return (
          <>
            {checkFarmFree ? (
              <Popover content={<>{t('messageCheckFree')}</>}>
                <Button
                  className=" btnCheckSell blockchain__button"
                  disabled={checkFarmFree}
                >
                  Sell
                </Button>
              </Popover>
            ) : (
              <Button
                className="blockchain__button"
                onClick={() => {
                  showModalSell()
                }}
              >
                Sell
              </Button>
            )}
          </>
        )
      } else {
        return <Button className="blockchain__button">Not Sell</Button>
      }
    }
  }
  return (
    <BodyCommon>
      {isLoading && <LoadingFarmDetail />}
      {!isLoading && (
        <div className="relative container__common mx-auto z-10 top-[84px]">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-4 pt-5 sm:pt-12">
            <div className="flex flex-col mb-3 sm:mb-0 items-center">
              <div className="relative card__detail w-[93.2%] h-[300px] md:w-[93.2%] md:h-[656px] flex items-center justify-center">
                <div className="relative flex items-center justify-center h-full w-full">
                  <div className="relative flex flex-col w-[93%] h-[93%] justify-between">
                    <div
                      className="h-full flex flex-col items-center p-[10px] text-[18px] justify-center bg-cover bg-no-repeat"
                      style={{
                        backgroundImage: `url(../imgs/farm/bgFarm/${getImgByFarmTerrain(
                          farmDetail?.farm_terrain,
                        )}.png)`,
                        clipPath:
                          'polygon(97% 100%, 100% 97%, 100% 3%, 97% 0%, 3% 0%, 0% 3%, 0% 97%, 3% 100%)',
                      }}
                    >
                      <img
                        alt=""
                        className="relative w-full h-full card__monster_img object-contain ease-in-out duration-300"
                        src={`../imgs/farm/${getImgByFarmTerrain(
                          farmDetail?.farm_terrain,
                        )}.png`}
                      />
                      <div className="absolute top-[17%] left-2 sm:top-[5%] sm:left-[10%]">
                        <div className="w-[80px] h-[80px] bg-[#4d638c] border rounded-[5px] flex items-center justify-center">
                          <img
                            className="w-[95%] h-[95%]"
                            src={`../imgs/farm/iconFarm/${getImgByFarmTerrain(
                              farmDetail?.farm_terrain,
                            )}.png`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Card className="mt-3 w-full">
                <Row>
                  <Col span={12} className="text-[16px]">
                    <div className="mb-3 font__M__plus font-bold">
                      {t('farmDetail.ownerAddress')}
                    </div>
                    <div className="mb-3 font__M__plus font-bold">
                      {t('farmDetail.contractAddress')}
                    </div>
                    <div className="font__M__plus font-bold">
                      {t('farmDetail.tokenID')}
                    </div>
                  </Col>
                  <Col span={12} className="text-end">
                    <div className="text-[#40a9ff] mb-3 font__M__plus font-medium">
                      <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}address/${
                          farmDetail?.orderId
                            ? farmDetail?.seller
                            : farmDetail?.owner
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CopyAddressCommon>
                          {farmDetail?.orderId
                            ? convertAddressToProperFormat(farmDetail?.seller)
                            : convertAddressToProperFormat(farmDetail?.owner)}
                        </CopyAddressCommon>
                      </a>
                    </div>
                    <div className="mb-3 font__M__plus font-medium">
                      {/* <CopyAddressCommon>
                        {farmDetail?.contractAddress ?? configs.ADDRESS_FARM}
                      </CopyAddressCommon> */}
                      <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}address/${
                          farmDetail?.contractAddress ?? configs.ADDRESS_FARM
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CopyAddressCommon>
                          {farmDetail?.contractAddress ?? configs.ADDRESS_FARM}
                        </CopyAddressCommon>
                      </a>
                    </div>
                    <div className="font__M__plus font-medium">
                    <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}token/${
                          farmDetail?.contractAddress ?? configs.ADDRESS_FARM
                        }/instance/${farmDetail?.farm_nft_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                      {farmDetail?.farm_nft_id}
                    </a>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>

            <div className="col-span-2">
              <div className="flex items-center">
                <div className="title__monster_detail">
                  {farmDetail?.farm_name}
                </div>
              </div>

              <Card className="mt-3 mb-8">
                {farmDetail?.price ? (
                  <div>
                    <Row>
                      <Col span={12} className="flex items-center">
                        {farmDetail?.price && (
                          <div className="relative z-10 flex items-center">
                            <img
                              className="mr-2"
                              src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                              alt=""
                              width={32}
                              height={32}
                            />
                            <div className="flex items-center">
                              <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                {formatNumber(farmDetail?.price)}
                              </div>
                              <span className="text-[16px] sm:text-[20px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    farmDetail?.price,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col span={12} className={`${'text-end'}`}>
                        <ButtonFarm />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="flex items-center">
                        <div className="mr-3">Floor Price</div>
                        <div className="relative z-10 flex items-center">
                          <img
                            className="mr-2"
                            src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                            alt=""
                            width={22}
                            height={22}
                          />
                          <div className="flex items-center">
                            <div className="flex text-[14px] sm:text-[16px] font-[700]">
                              {formatNumber(farmDetail?.floorPrice)}
                            </div>
                            <span className="text-[10px] sm:text-[14px] flex">
                              <div className="px-2"> ~ </div> $
                              {formatNumber(
                                convertOasToUSD(
                                  farmDetail?.floorPrice,
                                  allTEMAs?.tema,
                                ),
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <>
                    <Row>Floor Price</Row>
                    <Row>
                      <Col span={12} className="flex items-center">
                        <div className="relative z-10 flex items-center">
                          <img
                            className="mr-2"
                            src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                            alt=""
                            width={32}
                            height={32}
                          />
                          {/* <div className="flex items-center">
                            <div className="flex text-[20px] sm:text-[32px] font-[700]">
                              {formatNumber(farmDetail?.floorPrice)}
                            </div>
                            <span className="text-[16px] sm:text-[20px] flex">
                              <div className="px-2"> ~ </div> $
                              {formatNumber(
                                convertOasToUSD(
                                  farmDetail?.floorPrice,
                                  allTEMAs?.tema,
                                ),
                              )}
                            </span>
                          </div> */}

                          {farmDetail?.floorPrice ? (
                            <div className="flex items-center">
                              <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                {formatNumber(farmDetail?.floorPrice)}
                              </div>
                              <span className="text-[16px] sm:text-[20px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    farmDetail?.floorPrice,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="text-[24px]">No Listing</div>
                          )}
                        </div>
                      </Col>
                      <Col span={12} className={`${'text-end'}`}>
                        <ButtonFarm />
                      </Col>
                    </Row>
                  </>
                )}
              </Card>

              <ParameterFarm data={farmDetail} t={t} />
            </div>
          </div>

          <div className="mt-5 mb-20">
            <Datatable
              scroll={{ x: 1300 }}
              breadcrumb={
                <div className=" text-[24px] mb-5">
                  {t('farmDetail.tradingHistory')}
                </div>
              }
              columns={columns}
              dataSource={dataHistories}
              loading={false}
              metadata={{
                perPage: pageSize,
                setPerPage: setPageSize,
                page,
                setPage,
                total: totalData === 0 ? 1 : totalData,
              }}
              showPagination
            />
          </div>
        </div>
      )}
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={farmDetail}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
      />
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        name={`${farmDetail?.farm_name} (#${farmDetail?.farm_nft_id})`}
        loadingModal={loadingModal}
        // name={'Monster'}
        // tema={tema}
      />

      <SellModal
        action={{
          isModalOpen: isModalSellOpen,
          handleOk: handleSell,
          handleCancel: handleCancelModalSell,
        }}
        t={t}
        tokenID={farmDetail?.farm_nft_id}
        loadingModal={loadingModal}
        name={`${farmDetail?.farm_name} (#${farmDetail?.farm_nft_id})`}
        balance={balanceOAS}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        name={`${farmDetail?.farm_name} (#${farmDetail?.farm_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        name={`${farmDetail?.farm_name} (#${farmDetail?.farm_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedSell
        action={{
          isModalOpen: isModalSuccessSellOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccessSell,
        }}
        t={t}
        name={`${farmDetail?.farm_name} (#${farmDetail?.farm_nft_id})`}
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
    </BodyCommon>
  )
}

export default FarmDetail
