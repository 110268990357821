import { Button, Col, Drawer, Dropdown, Pagination, Row, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyCommon from '~/components/shared-components/BodyCommon'
import {
  HASH_SORT_MKP,
  HASH_SORT_MKP_JA,
  SCREEN_SIZE,
} from '~/common/constants'
import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons'
import { useQueryState } from '~/common/hooks/useQueryState'
import { FilterHashNFT } from './components/FilterHashNFT'
import { HashCard } from '~/components/shared-components/Card/HashCard'
import { NoData } from '~/components/shared-components/NoData'
import { useMonster } from '~/views/redux/hooks/useMonster'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { getSpeciesValue } from '~/helpers/Species'
import { getNameItem, getNameItemByContract } from '~/helpers/ItemName'
import { scrollToTop } from '~/helpers/common'
import CheckoutModal from '~/components/Modals/Checkout'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import SelectWallet from '~/components/Modals/SelectWallet'
import configs from '~/configs'
import { useAccount } from '~/common/hooks/useAccount'
import getImageHash from '~/assets/data/ImageHash.json'
import {
  checkTransactionConfirmation,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { ifaceContractMarketplace } from '~/blockchain/contract'
import { useSDK } from '@metamask/sdk-react'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import { useMediaQuery } from 'react-responsive'

export const HashNFT = () => {
  const { sdk } = useSDK()
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  // eslint-disable-next-line
  const { t } = useTranslation()
  const [sort, setSort] = useState(HASH_SORT_MKP[0])
  const [typeSort, setTypeSort] = useState('-1')
  const [openFilterHash, setOpenFilterHash] = useState(false)
  // eslint-disable-next-line
  const { keyword, setKeyword } = useQueryState()
  const [mainSeedFilter, setMainSeedFilter] = useState(
    JSON.parse(localStorage.getItem('hashNFTMarketplace'))?.mainSeedFilter ||
      [],
  )

  const [category, setCategory] = useState(
    JSON.parse(localStorage.getItem('hashNFTMarketplace'))?.category || [],
  )
  const [regenerationCount, setRegenerationCount] = useState(
    JSON.parse(localStorage.getItem('hashNFTMarketplace'))
      ?.regenerationCount || [0, 5],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('hashNFTMarketplace'))?.pageSize || 10,
  )
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('hashNFTMarketplace'))?.page || 1,
  )

  const { user } = useAccount()
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [nftActive, setNftActive] = useState(0)
  const [txHash, setTxHash] = useState()
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)

  const {
    actions,
    data: { allHashes, isLoading, totalHash },
  } = useMonster()

  const {
    actions: actionsTema,
    data: { allTEMAs },
  } = useCommon()

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  useEffect(() => {
    if (user) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            nftActive?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(nftActive?.price)),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  nftActive?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(nftActive?.price)),
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [nftActive?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [nftActive?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      search: keyword,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      category: category?.map((data) => data?.name),
      main_seed: mainSeedFilter,
      regenerationCount: {
        from: regenerationCount[0],
        to: regenerationCount[1],
      },
    }
    actions.getAllMonsterHash(params, () => {
      scrollToTop()
    })
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    keyword,
    category,
    mainSeedFilter,
    regenerationCount,
  ])

  const handleChangeSort = (e) => {
    setSort(HASH_SORT_MKP[e.key])
  }

  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }

  const handleSubmit = (values) => {
    setKeyword(values?.search ? values?.search?.trim() : '')
  }

  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }

  useEffect(() => {
    refreshData()
  }, [
    txHash,
    page,
    pageSize,
    sort,
    typeSort,
    category,
    mainSeedFilter,
    regenerationCount,
  ])

  useEffect(() => {
    if (allHashes?.length === 0 && page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    allHashes,
    pageSize,
    keyword,
    sort,
    typeSort,
    category,
    mainSeedFilter,
  ])

  useEffect(() => {
    actionsTema.getTema()
  }, [])
  const pageFilter = JSON.parse(
    localStorage.getItem('hashNFTMarketplace'),
  )?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])
  const clearAll = () => {
    setMainSeedFilter([])
    setCategory([])
    setRegenerationCount([])
  }
  const showDrawer = () => {
    setOpenFilterHash(true)
  }

  const onClose = () => {
    setOpenFilterHash(false)
  }
  function getItemGraphic(itemId) {
    // Kiểm tra điều kiện đặc biệt trước khi xử lý
    if (itemId === 'UPA 1912') {
      itemId = 'UPA_1912'
    }
    if (itemId === 'UPA Gorem') {
      itemId = 'UPA_Gorem'
    }
    if (itemId === 'UPA AC') {
      itemId = 'UPA_AC'
    }
    if (itemId === 'UPA ODEN') {
      itemId = 'UPA_ODEN'
    }
    if (itemId === 'UPA Ossan') {
      itemId = 'UPA_Ossan'
    }
    if (itemId === 'UPA Villain') {
      itemId = 'UPA_Villain'
    }
    if (itemId === 'Yamato T Shirt') {
      itemId = 'YamatoT_Shirt'
    }
    if (itemId === 'Sound Horse') {
      itemId = 'Soundhorse'
    }

    // Loại bỏ khoảng trắng
    let formattedItemId = itemId?.replace(/\s+/g, '')

    for (let i = 0; i < getImageHash.length; i++) {
      let formattedImageHashItemId = getImageHash[i].itemId.replace(/\s+/g, '')

      if (formattedImageHashItemId === formattedItemId) {
        return getImageHash[i].itemGraphic
      }
    }
    return null
  }
  return (
    <BodyCommon>
      <Row className={`${isMobile ? '' : 'container__common mx-auto h-full'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterHashNFT
                page={page}
                pageSize={pageSize}
                mainSeedFilter={mainSeedFilter}
                category={category}
                regenerationCount={regenerationCount}
                setMainSeedFilter={setMainSeedFilter}
                setCategory={setCategory}
                setRegenerationCount={setRegenerationCount}
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="z-10 pl-0 sm:pl-[15px] relative sm:sticky top-0 mt-0 sm:mt-[104px] pb-12">
            <div className="pt-0" style={{ marginTop: '100px' }}>
              <div className="flex mt-5 items-center justify-between flex-wrap">
                <div
                  className={`${
                    isMobile ? 'text-[24px] ml-[11px]' : 'text-[24px]'
                  }`}
                >
                  {t('countHash', {
                    total: totalHash,
                  })}
                  {totalHash > 1 ? t('es') : ''}
                </div>
                {isMobile ? (
                  <div className="flex items-center text-[16px] gap-2 justify-normal sm:justify-end">
                    <Dropdown
                      className="flex items-center cursor-pointer ml-3  sort__value"
                      menu={{
                        items:
                          user?.player_language === 'en'
                            ? HASH_SORT_MKP
                            : HASH_SORT_MKP_JA,
                        selectable: true,
                        onClick: handleChangeSort,
                      }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                        <div className="title">
                          {' '}
                          {t(`common.filter.sort.${sort?.valueLanguage}`)}
                        </div>
                      </Button>
                    </Dropdown>

                    <Button
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                      onClick={() => {
                        handleChangeTypeSort()
                      }}
                    >
                      <ArrowUpOutlined
                        style={{ fontSize: 18 }}
                        rotate={typeSort === '1' ? 0 : 180}
                      />
                    </Button>

                    <div>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                      >
                        <FilterOutlined />
                        {t('filter')}
                      </Button>
                      <Drawer
                        title={t('filter')}
                        width={330}
                        placement="left"
                        onClose={onClose}
                        open={openFilterHash}
                        extra={
                          <div
                            className="text-[16px] text-[#FAAD14] cursor-pointer"
                            onClick={() => {
                              clearAll()
                            }}
                          >
                            {t('clearAll')}
                          </div>
                        }
                      >
                        <div className="flex w-full h-full">
                          <FilterHashNFT
                            page={page}
                            pageSize={pageSize}
                            mainSeedFilter={mainSeedFilter}
                            category={category}
                            regenerationCount={regenerationCount}
                            setMainSeedFilter={setMainSeedFilter}
                            setCategory={setCategory}
                            setRegenerationCount={setRegenerationCount}
                            classCustom="filter__profile"
                          />
                        </div>
                      </Drawer>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center text-[16px] gap-2 justify-normal sm:justify-end">
                    <div> {t('sortBy')}</div>

                    <Dropdown
                      className="flex items-center cursor-pointer ml-3  sort__value"
                      menu={{
                        items:
                          user?.player_language === 'en'
                            ? HASH_SORT_MKP
                            : HASH_SORT_MKP_JA,
                        selectable: true,
                        onClick: handleChangeSort,
                      }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                        <div className="title">
                          {t(`common.filter.sort.${sort?.valueLanguage}`)}
                        </div>
                      </Button>
                    </Dropdown>

                    <Button
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                      onClick={() => {
                        handleChangeTypeSort()
                      }}
                    >
                      <ArrowUpOutlined
                        style={{ fontSize: 18 }}
                        rotate={typeSort === '1' ? 0 : 180}
                      />
                    </Button>
                  </div>
                )}
              </div>

              {/* Monster */}
              <Spin className="min-h-[350px]" spinning={isLoading}>
                <div className="flex flex-wrap justify-center gap-4 mt-5">
                  {allHashes?.map((data, i) => {
                    let image
                    switch (data?.type_name) {
                      case 'Genesis Hash':
                      case 'General Hash':
                        image = getSpeciesValue(data?.group, data?.species)
                          ?.image
                          ? `${
                              process.env.REACT_APP_BASE_URL_IMAGE
                            }/public/image/image/hash/${
                              getSpeciesValue(data?.group, data?.species)?.image
                            }.png`
                          : '../imgs/hash/golem.png'
                        break
                      case 'Hash Chip NFT':
                        image = '../imgs/common/result.png'
                        break
                      default:
                        image =
                          data?.type_name === 'Regeneration Hash' &&
                          getNameItem(3, data?.tokenId)?.image
                            ? `${
                                process.env.REACT_APP_BASE_URL_IMAGE
                              }/public/image/image/items/${
                                getNameItem(3, data?.tokenId)?.image
                              }.png`
                            : `${
                                process.env.REACT_APP_BASE_URL_IMAGE
                              }/public/image/image/hash/${getItemGraphic(
                                data?.main_seed,
                              )}`
                        break
                    }
                    return (
                      <HashCard
                        t={t}
                        key={i?.toString()}
                        tema={allTEMAs}
                        data={data}
                        button={{
                          title:
                            data?.seller?.toLowerCase() ===
                            user?.address_wallet?.toLowerCase()
                              ? 'Cancel'
                              : 'Buy',
                          functionButton: () => {
                            setNftActive(data)
                            if (
                              data?.seller?.toLowerCase() ===
                              user?.address_wallet?.toLowerCase()
                            ) {
                              showModalCancelSell()
                            } else {
                              showModalCheckout()
                            }
                          },
                        }}
                        img={image}
                      />
                    )
                  })}
                </div>
              </Spin>

              {!isLoading && totalHash === 0 && <NoData />}

              {totalHash > 0 && (
                <div className="pagination__common">
                  <Pagination
                    onChange={onChangePage}
                    onShowSizeChange={onChangePageSize}
                    className="mt-5"
                    size="small"
                    locale={{ jump_to: 'Go to', page: '' }}
                    total={totalHash}
                    // defaultCurrent={page}
                    current={page}
                    pageSize={pageSize}
                    showSizeChanger
                    showQuickJumper
                    showTitle
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={nftActive}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
        name={
          nftActive?.type_name === 'Genesis Hash' ||
          nftActive?.type_name === 'General Hash'
            ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
            : getNameItemByContract(
                nftActive?.contractAddress,
                nftActive?.tokenId,
              )?.name
        }
      />
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        name={
          (nftActive?.type_name === 'Genesis Hash' ||
          nftActive?.type_name === 'General Hash'
            ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
            : getNameItemByContract(
                nftActive?.contractAddress,
                nftActive?.tokenId,
              )?.name || nftActive?.main_seed) || nftActive?.name
        }
        loadingModal={loadingModal}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        name={
          (nftActive?.type_name === 'Genesis Hash' ||
          nftActive?.type_name === 'General Hash'
            ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
            : getNameItemByContract(
                nftActive?.contractAddress,
                nftActive?.tokenId,
              )?.name || nftActive?.main_seed) || nftActive?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        name={
          nftActive?.type_name === 'Genesis Hash' ||
          nftActive?.type_name === 'General Hash'
            ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
            : getNameItemByContract(
                nftActive?.contractAddress,
                nftActive?.tokenId,
              )?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
    </BodyCommon>
  )
}

export default HashNFT
