import { InfoCircleFilled } from '@ant-design/icons'
import { Col, Image, Modal, Popover, Row } from 'antd'
import {
  IMAGE_FALLBACK,
  converImageSkill,
  getColorRankSkill,
} from '~/common/constants'
import { ButtonHexagon } from '~/components/shared-components/Button/ButtonHexagon'
import { ButtonPolygon } from '~/components/shared-components/Button/ButtonPolygon'
import { HexGrid, Layout, Hexagon } from 'react-hexgrid'
import skillData from '~/assets/data/monster_skill.data.json'
import { useEffect, useState } from 'react'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { getHexagonMonsterSkill } from '~/helpers/getHexagonMonsterSkill'

const convertNumber = (value) => {
  if (value && value?.length > 0) {
    return (value[0] * 100)?.toFixed()
  }
  return value?.toFixed()
}

const convertNumberRank = (value) => {
  if (value && value?.length > 0) {
    return value[1]
  }
  return 'E'
}

const convertNumberPercent = (value) => {
  if (value && value.length > 0) {
    let result = (value[0] * 100).toFixed(2)
    result = parseFloat(result)
    return result.toString()
  }
  let result = parseFloat(value).toFixed(2)
  // Nếu kết quả có phần thập phân ".00" thì loại bỏ chúng
  result = parseFloat(result)
  return result.toString()
}

const EmptyBuff = () => {
  return (
    <div className="flex items-center gap-5">
      <img
        src={`${process.env.PUBLIC_URL}/imgs/common/hexagon__block.png`}
        alt=""
      />
      <div>-</div>
    </div>
  )
}

const BuffData = (props) => {
  const { type, value } = props
  const displayValue =
    typeof value === 'number'
      ? `${parseFloat((value * 100).toFixed(2))}%`
      : value

  return (
    <div className="flex items-center gap-5">
      <ButtonHexagon
        borderColor={'bg-[#9D9D9C]'}
        bgColor={'!bg-[#000F38] hover:!bg-[#000F38]'}
        text={
          <img
            className="w-[100%] h-[100%] object-contain "
            src={`${process.env.PUBLIC_URL}/imgs/buff/${type}.png`}
            alt=""
          />
        }
      />

      <div>{displayValue}</div>
    </div>
  )
}

export default function SkillDetailModal(props) {
  const { skillInfo, action, t, idSkill, skillIdMonster } = props
  const skilId = converImageSkill(skillIdMonster)?.slice(0, -8)
  const [skillNumber, setSkillNumber] = useState(1)
  const [skillLevel, setSkillLevel] = useState(1)
  const [parameterSkill, setParameterSkill] = useState({})
  const skillInfoJson = skillData[skilId]
  const {
    data: { locale },
  } = useTheme()

  useEffect(() => {
    setSkillNumber(skillIdMonster?.charAt(skillIdMonster?.length - 1))
    if (skillInfo?.skill_level === 0) {
      setSkillLevel(1)
    } else {
      setSkillLevel(skillInfo?.skill_level)
    }
  }, [skillInfo])
  useEffect(() => {
    setParameterSkill(skillInfoJson?.skill_list[skillNumber - 1])
  }, [skillNumber])
  const hexagonSize = { x: 6, y: 6 }

  const getRank = () => {
    const skill = skillInfoJson?.learning
    if (!skill) {
      return null
    }
    return skill['Skill' + idSkill]['lv.' + skillLevel]
  }
  const getShadowColor = (type) => {
    switch (type) {
      case 'INT':
        return 'drop-shadow-[0_0_3px_#883EDD]'
      case 'STR':
        return 'drop-shadow-[0_0_3px_#E80000]'
      case 'Heal':
        return 'drop-shadow-[0_0_3px_#077E0D]'
      case 'Support':
        return 'drop-shadow-[0_0_3px_#1D599E]'
      default:
        return ''
    }
  }
  const getBoderColor = (type) => {
    switch (type) {
      case 'INT':
        return 'bg-[#883EDD]'
      case 'STR':
        return 'bg-[#E80000]'
      case 'Heal':
        return 'bg-[#077E0D]'
      case 'Support':
        return 'bg-[#1D599E]'
      default:
        return ''
    }
  }
  const getBackgroundColor = (type) => {
    switch (type) {
      case 'INT':
        return '!bg-[#883EDD] '
      case 'STR':
        return '!bg-[#E80000] '
      case 'Heal':
        return '!bg-[#077E0D] '
      case 'Support':
        return '!bg-[#1D599E] '
      default:
        return ''
    }
  }
  const getBackgroundColorIconSkill = (type) => {
    switch (type) {
      case 'Earth':
        return '!bg-[#E49A6E]'
      case 'FIre':
        return '!bg-[#C94A4A]'
      case 'Slash':
        return '!bg-[#9E9E9E]'
      case 'Strike':
        return '!bg-[#C87431]'
      case 'Thurst':
        return '!bg-[#D6E073]'
      case 'Thrust':
        return '!bg-[#D6E073]'
      case 'Water':
        return '!bg-[#4DC1FF]'
      case 'Wind':
        return '!bg-[#4DCB53]'
      default:
        return ''
    }
  }
  const hexagonDataRange =
    skilId && getHexagonMonsterSkill(skilId, Number(skillNumber))
      ? getHexagonMonsterSkill(skilId, Number(skillNumber))[0]
      : []

  const hexagonDataScope =
    skilId && getHexagonMonsterSkill(skilId, Number(skillNumber))
      ? getHexagonMonsterSkill(skilId, Number(skillNumber))[1]
      : []

  const hexagonCountRange = hexagonDataRange.length
  const hexagonCountScope = hexagonDataScope.length
  const getDynamicSize = (count) => {
    if (count === 1) return { x: 8, y: 8 }
    if (count > 60) return { x: 4, y: 4 }
    if (count > 24) return { x: hexagonSize.x * 0.8, y: hexagonSize.y * 0.8 }
    return hexagonSize
  }

  const sizeRange = getDynamicSize(hexagonCountRange)
  const sizeScope = getDynamicSize(hexagonCountScope)
  return (
    <>
      <Modal
        className="modal__skill"
        width={900}
        title={
          <div className="flex">
            <h3 className="flex justify-center text-[20px] font-medium mr-2">
              Skill Details
            </h3>
          </div>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={null}
      >
        <div
          className="text-[20px] text-center mb-3  "
          style={{ fontFamily: 'Rexliafree' }}
        >
          {parameterSkill?.Name && locale === 'en'
            ? parameterSkill?.Name?.split('\n')[1]
            : parameterSkill?.Name?.split('\n')[0]}
        </div>
        {/* INFO */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-4 mb-3">
          {/* Left Common*/}
          <div className="col-span-2 mb-3 sm:mb-0">
            <Row className="mb-3">
              <Col className="flex" span={24}>
                <div className="relative !w-[100px] h-[100px]">
                  <ButtonPolygon
                    clipPathOut="polygon__outside_img"
                    clipPathIn="polygon__inside_img"
                    borderColor={'bg-[#00C9FA]'}
                    textCss="flex items-center justify-center"
                    customSize={'w-[100px] h-[100px]'}
                    text={
                      <>
                        <div
                          className="absolute top-0 left-0 w-[30%] h-[30%] text-white z-10 text-[20px] polygon__inside_rank"
                          style={{
                            ...getColorRankSkill(getRank()),
                            background: 'rgba(0, 0, 0, 0.75)',
                          }}
                        >
                          {getRank()}
                        </div>

                        <Image
                          className="!w-[100px] h-[100px]"
                          style={{ height: '100px' }}
                          src={`${
                            process.env.REACT_APP_BASE_URL_IMAGE_SKILL
                          }/${converImageSkill(skillIdMonster)}.png`}
                          fallback={IMAGE_FALLBACK}
                          preview={false}
                          loading="lazy"
                        />
                      </>
                    }
                  />
                </div>

                <div className="">
                  <Row>
                    <Col span={24} className="mb-3 ml-3">
                      <div className="flex gap-2 ">
                        <ButtonPolygon
                          className="mr-2"
                          shadownBtn={getShadowColor(
                            parameterSkill?.Type && parameterSkill?.Type[0],
                          )}
                          customSize={'w-[88px] h-[32px]'}
                          borderColor={getBoderColor(
                            parameterSkill?.Type && parameterSkill?.Type[0],
                          )}
                          bgColor={getBackgroundColor(
                            parameterSkill?.Type && parameterSkill?.Type[0],
                          )}
                          textCss={'text-[16px]'}
                          text={parameterSkill?.Type && parameterSkill?.Type[0]}
                        />

                        {parameterSkill?.ElementType &&
                          parameterSkill?.ElementType?.length > 0 &&
                          parameterSkill?.ElementType[0] !== 0 && (
                            <ButtonPolygon
                              shadownBtn="drop-shadow-[0_0_3px_#2ca0ffcc]"
                              clipPathOut="polygon__outside_sm"
                              clipPathIn="polygon__inside_sm"
                              // bgColor={'!bg-[#000F38]'}
                              bgColor={
                                parameterSkill?.Type[0] === 'Support'
                                  ? 'bg-[#1D599E]'
                                  : parameterSkill?.Type[0] === 'Heal'
                                  ? 'bg-[#077E0D]'
                                  : getBackgroundColorIconSkill(
                                      parameterSkill?.ElementType &&
                                        parameterSkill?.ElementType[0],
                                    )
                              }
                              borderColor={'bg-[#00C9FA]'}
                              textCss="flex items-center justify-center"
                              customSize={'w-[32px] h-[32px]'}
                              text={
                                <img
                                  className="w-[60%] h-[60%] object-contain"
                                  src={`${
                                    process.env.PUBLIC_URL
                                  }/imgs/iconSkillDetail/${
                                    parameterSkill?.ElementType &&
                                    parameterSkill?.ElementType[0] === 'Blunt'
                                      ? 'Strike'
                                      : parameterSkill?.Type[0] === 'Support'
                                      ? 'support'
                                      : parameterSkill?.Type[0] === 'Heal'
                                      ? 'heal'
                                      : parameterSkill?.ElementType[0]
                                  }.png`}
                                  alt=""
                                />
                              }
                            />
                          )}
                      </div>
                    </Col>
                    <Col span={24} className="ml-3">
                      <div className="flex gap-5">
                        <div className="flex flex-col items-center justify-center">
                          <div className="text-[10px]">COST</div>

                          <div className="text-[20px]">
                            {parameterSkill?.Cost &&
                              convertNumber(
                                parameterSkill?.Cost[skillLevel - 1],
                              )}
                          </div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                          <div className="text-[10px]">ST</div>
                          <div className="text-[20px]">
                            {parameterSkill?.StaminaConsumption &&
                              convertNumber(
                                parameterSkill?.StaminaConsumption[
                                  skillLevel - 1
                                ],
                              )}
                          </div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                          <div className="text-[10px]">DELAY</div>
                          <div className="text-[20px]">
                            {parameterSkill?.Delay &&
                              convertNumber(
                                parameterSkill?.Delay[skillLevel - 1],
                              )}
                          </div>
                        </div>

                        <div className="flex flex-col items-center justify-center">
                          <div className="text-[10px]">RANGE</div>
                          <div className="text-[20px]">
                            {parameterSkill?.Range &&
                              (typeof parameterSkill?.Range[skillLevel - 1] ===
                              'number'
                                ? convertNumber(
                                    parameterSkill?.Range[skillLevel - 1],
                                  )
                                : parameterSkill?.Range[skillLevel - 1])}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className="">
              <Col span={24} className="grid grid-cols-3 gap-3">
                {((parameterSkill?.Type && parameterSkill?.Type[0] === 'INT') ||
                  (parameterSkill?.Type &&
                    parameterSkill?.Type[0] === 'STR')) && (
                  <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[64px] text-center flex flex-col items-center">
                    <div className="text-[12px]">HP Damage</div>
                    <div className="text-[20px] flex items-center">
                      {(parameterSkill?.HPDMG &&
                        convertNumber(
                          parameterSkill?.HPDMG[skillLevel - 1],
                        )) !== '0' ? (
                        <img
                          className="mr-1 w-[20px] h-[20px]"
                          src={`${process.env.PUBLIC_URL}/svg/rankTagText/${
                            parameterSkill?.HPDMG &&
                            convertNumberRank(
                              parameterSkill?.HPDMG[skillLevel - 1],
                            )
                          }.svg`}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}

                      <div>
                        {parameterSkill?.HPDMG &&
                          parameterSkill.HPDMG[skillLevel - 1] &&
                          parameterSkill.HPDMG[skillLevel - 1][0]}
                      </div>
                    </div>
                  </div>
                )}
                {((parameterSkill?.Type &&
                  parameterSkill?.Type[0] === 'Heal') ||
                  (parameterSkill?.Type &&
                    parameterSkill?.Type[0] === 'Support')) && (
                  <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[64px] text-center flex flex-col items-center">
                    <div className="text-[12px]">HP Heal</div>
                    <div className="text-[20px] flex items-center">
                      {(parameterSkill?.HPHeal &&
                        convertNumber(
                          parameterSkill?.HPHeal[skillLevel - 1],
                        )) !== '0' ? (
                        <img
                          className="mr-1 w-[20px] h-[20px]"
                          src={`${process.env.PUBLIC_URL}/svg/rankTagText/${
                            parameterSkill?.HPHeal &&
                            convertNumberRank(
                              parameterSkill?.HPHeal[skillLevel - 1],
                            )
                          }.svg`}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}

                      <div>
                        {parameterSkill?.HPHeal &&
                          parameterSkill.HPHeal[skillLevel - 1] &&
                          parameterSkill.HPHeal[skillLevel - 1][0] * 100 + '%'}
                      </div>
                    </div>
                  </div>
                )}

                {((parameterSkill?.Type && parameterSkill?.Type[0] === 'INT') ||
                  (parameterSkill?.Type &&
                    parameterSkill?.Type[0] === 'STR')) && (
                  <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[64px] text-center flex flex-col items-center">
                    <div className="text-[12px]">ST Damage</div>
                    <div className="text-[20px] flex items-center">
                      {(parameterSkill?.STDMG &&
                        convertNumber(
                          parameterSkill?.STDMG[skillLevel - 1],
                        )) !== '0' ? (
                        <img
                          className="mr-1 w-[20px] h-[20px]"
                          src={`${process.env.PUBLIC_URL}/svg/rankTagText/${
                            parameterSkill?.STDMG &&
                            convertNumberRank(
                              parameterSkill?.STDMG[skillLevel - 1],
                            )
                          }.svg`}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}

                      <div>
                        {parameterSkill?.STDMG &&
                          parameterSkill.STDMG[skillLevel - 1] &&
                          parameterSkill.STDMG[skillLevel - 1][0]}
                      </div>
                    </div>
                  </div>
                )}

                {((parameterSkill?.Type &&
                  parameterSkill?.Type[0] === 'Heal') ||
                  (parameterSkill?.Type &&
                    parameterSkill?.Type[0] === 'Support')) && (
                  <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[64px] text-center flex flex-col items-center">
                    <div className="text-[12px]">ST Heal</div>
                    <div className="text-[20px] flex items-center">
                      {(parameterSkill?.STHeal &&
                        convertNumber(
                          parameterSkill?.STHeal[skillLevel - 1],
                        )) !== '0' ? (
                        <img
                          className="mr-1 w-[20px] h-[20px]"
                          src={`${process.env.PUBLIC_URL}/svg/rankTagText/${
                            parameterSkill?.STHeal &&
                            convertNumberRank(
                              parameterSkill?.STHeal[skillLevel - 1],
                            )
                          }.svg`}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}

                      <div>
                        {parameterSkill?.STHeal &&
                          parameterSkill.STHeal[skillLevel - 1] &&
                          parameterSkill.STHeal[skillLevel - 1][0]}
                      </div>
                    </div>
                  </div>
                )}

                <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[64px] text-center flex flex-col items-center">
                  <div className="text-[12px]">DELAY Damage</div>
                  <div className="text-[20px] flex items-center">
                    {(parameterSkill?.DelayDamage &&
                      convertNumber(
                        parameterSkill?.DelayDamage[skillLevel - 1],
                      )) !== '0' ? (
                      <img
                        className="mr-1 w-[20px] h-[20px]"
                        src={`${process.env.PUBLIC_URL}/svg/rankTagText/${
                          parameterSkill?.DelayDamage &&
                          convertNumberRank(
                            parameterSkill?.DelayDamage[skillLevel - 1],
                          )
                        }.svg`}
                        alt=""
                      />
                    ) : (
                      <></>
                    )}
                    <div>
                      {parameterSkill?.DelayDamage &&
                        parameterSkill.DelayDamage[skillLevel - 1] &&
                        parameterSkill.DelayDamage[skillLevel - 1][0]}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* Right Common*/}
          <div className="">
            <Row className="h-[100px] mb-3 ">
              <Col className="flex gap-2 mb-3" span={24}>
                <ButtonPolygon
                  className="mr-2"
                  active={skillLevel === 1}
                  text={'Level 1'}
                  block
                  customSize={'w-full h-[32px]'}
                  bgColor={'bg-[#000F38] hover:!bg-[#00C9FA]'}
                  borderColor={'bg-[#00C9FA]'}
                  onClick={() => {
                    setSkillLevel(1)
                  }}
                />
                <ButtonPolygon
                  className="mr-2"
                  active={skillLevel === 2}
                  text={'Level 2'}
                  block
                  customSize={'w-full h-[32px]'}
                  bgColor={'bg-[#000F38] hover:!bg-[#00C9FA]'}
                  borderColor={'bg-[#00C9FA]'}
                  onClick={() => {
                    setSkillLevel(2)
                  }}
                />
                <ButtonPolygon
                  className="mr-2"
                  active={skillLevel === 3}
                  text={'Level 3'}
                  block
                  customSize={'w-full h-[32px]'}
                  bgColor={'bg-[#000F38] hover:!bg-[#00C9FA]'}
                  borderColor={'bg-[#00C9FA]'}
                  onClick={() => {
                    setSkillLevel(3)
                  }}
                />
              </Col>
              <Col span={24}>
                <Row>
                  <Col
                    span={12}
                    className="text-center flex flex-col items-center"
                  >
                    <div className="text-[10px]">Execution Type</div>
                    <div className="text-[16px]">
                      {parameterSkill?.ExecutionType &&
                        parameterSkill?.ExecutionType[skillLevel - 1]}
                    </div>
                  </Col>
                  <Col
                    span={12}
                    className="text-center flex flex-col items-center"
                  >
                    <div className="text-[10px] ">Scope of Effect</div>
                    <div className="text-[16px]">
                      {parameterSkill?.ScopeofEffect &&
                        parameterSkill?.ScopeofEffect[skillLevel - 1]}
                      :{' '}
                      {parameterSkill?.ScopeofEffect2 &&
                        parameterSkill?.ScopeofEffect2[skillLevel - 1]}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="">
              <Col span={24} className="grid grid-cols-2 gap-3">
                <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[64px] text-center flex flex-col items-center">
                  <div className="text-[12px]">HIT</div>
                  <div className="text-[20px] flex items-center">
                    {(parameterSkill?.HIT &&
                      convertNumberPercent(
                        parameterSkill?.HIT[skillLevel - 1],
                      )) !== '0' ? (
                      <img
                        className="mr-1 w-[20px] h-[20px]"
                        src={`${process.env.PUBLIC_URL}/svg/rankTagText/${
                          parameterSkill?.HIT &&
                          convertNumberRank(parameterSkill?.HIT[skillLevel - 1])
                        }.svg`}
                        alt=""
                      />
                    ) : (
                      <></>
                    )}
                    <div>
                      {parameterSkill?.HIT &&
                        convertNumberPercent(
                          parameterSkill?.HIT[skillLevel - 1],
                        )}
                      %
                    </div>
                  </div>
                </div>

                <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[64px] text-center flex flex-col items-center">
                  <div className="text-[12px]">CRI</div>
                  <div className="text-[20px] flex items-center">
                    {(parameterSkill?.CRI &&
                      convertNumberPercent(
                        parameterSkill?.CRI[skillLevel - 1],
                      )) !== '0' ? (
                      <img
                        className="mr-1 w-[20px] h-[20px]"
                        src={`${process.env.PUBLIC_URL}/svg/rankTagText/${
                          parameterSkill?.CRI &&
                          convertNumberRank(parameterSkill?.CRI[skillLevel - 1])
                        }.svg`}
                        alt=""
                      />
                    ) : (
                      <></>
                    )}

                    <div>
                      {parameterSkill?.CRI &&
                        convertNumberPercent(
                          parameterSkill?.CRI[skillLevel - 1],
                        )}
                      %
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* BUFF / RANGE */}
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-0 sm:gap-4">
          {/* Left Common*/}
          <div className="col-span-3 mb-3 sm:mb-0">
            <Row className="mb-3">
              <Col span={24}>
                <div className="text-[16px]">Buffs</div>
              </Col>
              <Col span={24} className="grid grid-cols-3 gap-3">
                {parameterSkill?.Buff1 &&
                parameterSkill?.Buff1[skillLevel - 1]?.length > 0 &&
                parameterSkill?.Buff1[skillLevel - 1][0] !== 0 &&
                parameterSkill?.Buff1[skillLevel - 1][0] !== '' ? (
                  <>
                    <BuffData
                      type={parameterSkill?.Buff1[skillLevel - 1][0]}
                      value={parameterSkill?.Buff1[skillLevel - 1][1]}
                    />
                  </>
                ) : (
                  <EmptyBuff />
                )}

                {parameterSkill?.Buff2 &&
                parameterSkill?.Buff2[skillLevel - 1]?.length > 0 &&
                parameterSkill?.Buff2[skillLevel - 1][0] !== 0 &&
                parameterSkill?.Buff2[skillLevel - 1][0] !== '' ? (
                  <BuffData
                    type={parameterSkill?.Buff2[skillLevel - 1][0]}
                    value={parameterSkill?.Buff2[skillLevel - 1][1]}
                  />
                ) : (
                  <EmptyBuff />
                )}

                {parameterSkill?.Buff3 &&
                parameterSkill?.Buff3[skillLevel - 1]?.length > 0 &&
                parameterSkill?.Buff3[skillLevel - 1][0] !== 0 &&
                parameterSkill?.Buff3[skillLevel - 1][0] !== '' ? (
                  <BuffData
                    type={parameterSkill?.Buff3[skillLevel - 1][0]}
                    value={parameterSkill?.Buff3[skillLevel - 1][1]}
                  />
                ) : (
                  <EmptyBuff />
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col span={24}>
                <div className="text-[16px]">Debuffs</div>
              </Col>
              <Col span={24} className="grid grid-cols-3 gap-3">
                {parameterSkill?.Debuff1 &&
                parameterSkill?.Debuff1[skillLevel - 1]?.length > 0 &&
                parameterSkill?.Debuff1[skillLevel - 1][0] !== 0 &&
                parameterSkill?.Debuff1[skillLevel - 1][0] !== '' ? (
                  <BuffData
                    type={parameterSkill?.Debuff1[skillLevel - 1][0]}
                    value={parameterSkill?.Debuff1[skillLevel - 1][1]}
                  />
                ) : (
                  <EmptyBuff />
                )}
                {parameterSkill?.Debuff2 &&
                parameterSkill?.Debuff2[skillLevel - 1]?.length > 0 &&
                parameterSkill?.Debuff2[skillLevel - 1][0] !== 0 &&
                parameterSkill?.Debuff2[skillLevel - 1][0] !== '' ? (
                  <BuffData
                    type={parameterSkill?.Debuff2[skillLevel - 1][0]}
                    value={parameterSkill?.Debuff2[skillLevel - 1][1]}
                  />
                ) : (
                  <EmptyBuff />
                )}
                {parameterSkill?.Debuff3 &&
                parameterSkill?.Debuff3[skillLevel - 1]?.length > 0 &&
                parameterSkill?.Debuff3[skillLevel - 1][0] !== 0 &&
                parameterSkill?.Debuff3[skillLevel - 1][0] !== '' ? (
                  <BuffData
                    type={parameterSkill?.Debuff3[skillLevel - 1][0]}
                    value={parameterSkill?.Debuff3[skillLevel - 1][1]}
                  />
                ) : (
                  <EmptyBuff />
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col span={24}>
                <div className="text-[16px]">Descriptions</div>
              </Col>
              <Col span={24}>
                <div
                  className="text-[16px]"
                  dangerouslySetInnerHTML={{
                    __html:
                      parameterSkill?.description && locale === 'en'
                        ? parameterSkill?.description
                            ?.split('/')[1]
                            ?.replace(/\n/g, '<br />')
                        : parameterSkill?.description
                            ?.split('/')[0]
                            ?.replace(/\n/g, '<br />'),
                  }}
                />
              </Col>
            </Row>
          </div>
          {/* Right Common*/}
          <div className="grid grid-cols-1 gap-4">
            <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[auto] text-center flex flex-col items-center">
              <div className="text-[14px]" style={{ fontFamily: 'Rexliafree' }}>
                Range
              </div>
              <div className="relative h-full skill_detail">
                <HexGrid
                  width={'100%'}
                  height={'100%'}
                  viewBox="-50 -50 100 100"
                >
                  {/* Grid with manually inserted hexagons */}
                  <Layout
                    size={sizeRange}
                    flat={false}
                    spacing={1.2}
                    origin={{ x: 0, y: 0 }}
                  >
                    {skilId &&
                      getHexagonMonsterSkill(skilId, Number(skillNumber)) &&
                      getHexagonMonsterSkill(
                        skilId,
                        Number(skillNumber),
                      )[0]?.map((data, i) => (
                        <Hexagon
                          key={i?.toString()}
                          className={`${
                            data?.main === true
                              ? 'main'
                              : data?.main === false
                              ? 'sub'
                              : 'pointSub'
                          }`}
                          q={data?.q}
                          r={data?.r}
                          s={data?.s}
                          fill={`${
                            // data?.main === true ? 'gradientMain' : 'gradientSub'
                            data?.main === true
                              ? 'gradientMain'
                              : data?.main === false
                              ? 'gradientSub'
                              : 'gradientPointSub'
                          }`}
                        />
                      ))}
                  </Layout>
                  <defs>
                    <linearGradient
                      id="gradientSub"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#00345D', stopOpacity: 0.8 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: '#0097F8', stopOpacity: 0.8 }}
                      />
                    </linearGradient>

                    <linearGradient
                      id="gradientMain"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#3B3126CC', stopOpacity: 0.8 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: '#FFF044', stopOpacity: 0.8 }}
                      />
                    </linearGradient>

                    <linearGradient
                      id="gradientPointSub"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#3B3126CC', stopOpacity: 0.8 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: '#6A6A6A', stopOpacity: 0.8 }}
                      />
                    </linearGradient>
                  </defs>
                </HexGrid>
              </div>
            </div>

            <div className="bg-[#020f38] border border-[#1890FF] p-[10px] rounded-[5px] h-auto sm:h-[auto] text-center flex flex-col items-center">
              <div className="text-[14px]" style={{ fontFamily: 'Rexliafree' }}>
                Scope of Effect
              </div>
              <div className="relative h-full skill_detail">
                <HexGrid
                  width={'100%'}
                  height={'100%'}
                  viewBox="-50 -50 100 100"
                >
                  {/* Grid with manually inserted hexagons */}
                  <Layout
                    size={sizeScope}
                    flat={false}
                    spacing={1.2}
                    origin={{ x: 0, y: 0 }}
                  >
                    {skilId &&
                      getHexagonMonsterSkill(skilId, Number(skillNumber)) &&
                      getHexagonMonsterSkill(
                        skilId,
                        Number(skillNumber),
                      )[1]?.map((data, i) => (
                        <Hexagon
                          key={i?.toString()}
                          className={`${
                            data?.main === true
                              ? 'main'
                              : data?.main === false
                              ? 'sub'
                              : 'pointSub'
                          }`}
                          q={data?.q}
                          r={data?.r}
                          s={data?.s}
                          fill={`${
                            data?.main === true
                              ? 'gradientMain'
                              : data?.main === false
                              ? 'gradientSub'
                              : 'gradientPointSub'
                          }`}
                        />
                      ))}
                  </Layout>
                  <defs>
                    <linearGradient
                      id="gradientSub"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#00345D', stopOpacity: 0.8 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: '#0097F8', stopOpacity: 0.8 }}
                      />
                    </linearGradient>

                    <linearGradient
                      id="gradientMain"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#3B3126CC', stopOpacity: 0.8 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: '#FFF044', stopOpacity: 0.8 }}
                      />
                    </linearGradient>

                    <linearGradient
                      id="gradientPointSub"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: '#3B3126CC', stopOpacity: 0.8 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: '#6A6A6A', stopOpacity: 0.8 }}
                      />
                    </linearGradient>
                  </defs>
                </HexGrid>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
